import _ from 'lodash'
import type { BiUtils, Initializable, IModelsAPI } from '@wix/thunderbolt-symbols'
import type { BootstrapData } from '../../types'
import { PLATFORM_BI, PLATFORM_BI_LOGGER, BOOTSTRAP_DATA, MODELS_API } from './moduleNames'

const PlatformBi = (biUtils: BiUtils, { platformEnvData }: BootstrapData, modelsApi: IModelsAPI): Initializable => ({
	init: () => {
		const {
			bi: {
				isPreview,
				pageData: { pageNumber, isLightbox },
			},
		} = platformEnvData

		if (process.env.browser && !isLightbox && !isPreview) {
			const all_widgets = _.flatMap(modelsApi.getApplications(), (controllers) => _.map(controllers, 'controllerType'))

			const widgets_ids = _.uniq(all_widgets)

			biUtils
				.createBaseBiLoggerFactory()
				.logger()
				.report({
					src: 72,
					evid: 520,
					endpoint: 'bpm',
					params: {
						apps_ids: modelsApi.getApplicationIds(),
						widgets_ids,
						widgets_count: all_widgets.length,
						page_number: pageNumber,
					},
				})
		}
	},
})

export default {
	factory: PlatformBi,
	deps: [PLATFORM_BI_LOGGER, BOOTSTRAP_DATA, MODELS_API],
	name: PLATFORM_BI,
}
